import React from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { Menu, Transition } from '@headlessui/react'
import useUI from '../hooks/UIHooks'
import ToggleDark from './ToggleDark'
import Brand from '../images/Brand'
import Logo from '../images/Logo'

const Header = (): JSX.Element => {
	/** State */
	const { UI, toggleMobile, closeMobile } = useUI()
	/** Styled Components */
	const MobileMenu = tw.div`absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden bg-gray-900 bg-opacity-75 h-screen`
	const MenuButton = tw.button`text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-300 dark:hover:text-gray-500 cursor-pointer`
	const FooterMenuButton = tw.button`px-3 py-2 rounded-md text-base font-medium text-gray-900 block w-full text-left`
	return (
		<header tw="relative bg-white dark:bg-gray-900 z-20 shadow-md">
			<div tw="py-3">
				<nav tw="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
					<div tw="flex items-center flex-1">
						<div tw="flex items-center justify-between w-full md:w-auto">
							<Link to="/">
								<span tw="sr-only">Maytronics Australia</span>
								<Logo tw="h-10 md:h-9 lg:h-10 pt-2 w-auto text-mt-gray dark:text-white" role="img" />
							</Link>
							<div tw="-mr-2 flex items-center space-x-2 md:hidden">
								<ToggleDark />
								<button
									type="button"
									tw="bg-gray-200 dark:bg-gray-700 rounded-md p-2 inline-flex items-center justify-center text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
									id="main-menu"
									aria-haspopup="true"
									onClick={toggleMobile}
								>
									<span tw="sr-only">Open main menu</span>
									{/** Heroicon name: menu */}
									<svg
										tw="h-6 w-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
									</svg>
								</button>
							</div>
						</div>
						<div tw="hidden space-x-4 md:flex md:ml-8">
							<Menu>
								<div tw="relative">
									<Menu.Button as={MenuButton}>Pool Cleaners</Menu.Button>
									<Menu.Items tw="absolute flex flex-col left-0 top-6 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black flex items-center" to="/dolphin">
												<div
													tw="w-8 h-8 bg-contain bg-no-repeat mr-2"
													style={{
														backgroundImage:
															'url(https://images.ctfassets.net/ftcnv5up9dxv/68iEdnis34dT15KA1D2rMT/bf3086aede408b1961b4aa9d385075e7/D_is_for_Dolphin.png)',
													}}
												></div>
												<p>
													<span tw="font-bold">Dolphin</span>
													<br></br>
													Robotic Pool Cleaners
												</p>
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/m-line">
												Dolphin M Line
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/s-line">
												Dolphin S Line
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/x-line">
												Dolphin X Line
											</Link>
										</Menu.Item>
										{/* <Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/e-line">
												Dolphin E Line
											</Link>
										</Menu.Item> */}
										{process.env.GATSBY_LOCALE === 'en-PH' ? (
											<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
												<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/liberty-line">
													Dolphin Liberty Line
												</Link>
											</Menu.Item>
										) : (
											''
										)}
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/which-model">
												Which Robot?
											</Link>
										</Menu.Item>
									</Menu.Items>
								</div>
							</Menu>
							<Menu>
								<div tw="relative">
									<Menu.Button as={MenuButton}>Commercial</Menu.Button>
									<Menu.Items tw="absolute flex flex-col left-0 top-6 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black flex items-center" to="/dolphin/commercial">
												<div
													tw="w-8 h-8 bg-contain bg-no-repeat mr-2"
													style={{
														backgroundImage:
															'url(https://images.ctfassets.net/ftcnv5up9dxv/68iEdnis34dT15KA1D2rMT/bf3086aede408b1961b4aa9d385075e7/D_is_for_Dolphin.png)',
													}}
												></div>
												<p>
													<span tw="font-bold">Dolphin Commercial</span>
													<br></br>
													Robotic Pool Cleaners
												</p>
											</Link>
										</Menu.Item>
									</Menu.Items>
								</div>
							</Menu>
							<Menu>
								<div tw="relative">
									<Menu.Button as={MenuButton}>Support</Menu.Button>
									<Menu.Items tw="absolute flex flex-col left-0 top-6 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/contact-us">
												Contact
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link
												target="_blank"
												rel="noreferrer"
												tw="text-sm text-gray-600 hover:text-black"
												to="https://maytronics.com.au/support/faq/"
											>
												Knowledge Base
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link
												target="_blank"
												rel="noreferrer"
												tw="text-sm text-gray-600 hover:text-black"
												to="https://maytronics.com.au/support/registration"
											>
												Warranty Registration
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link
												target="_blank"
												rel="noreferrer"
												tw="text-sm text-gray-600 hover:text-black"
												to="https://maytronics.com.au/support/dolphins-purchased-from-outside-australia/"
											>
												Overseas Purchases
											</Link>
										</Menu.Item>
									</Menu.Items>
								</div>
							</Menu>
						</div>
					</div>
					<div tw="hidden md:flex md:items-center md:space-x-2">
						{/* <Link
							to="#"
							tw="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-yellow-500 hover:bg-yellow-500"
						>
							Shop
						</Link> */}
						<Link
							to="/contact-us?subject=demo"
							tw="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-yellow-500 hover:bg-yellow-400"
						>
							Book a Demo
						</Link>
					</div>
				</nav>
			</div>

			{/** Mobile menu, show/hide based on menu open state. */}
			<Transition
				show={UI.isMobile}
				as={MobileMenu}
				enter="transition-opacity duration-75"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div tw="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
					<div tw="px-5 pt-4 flex items-center justify-between">
						<div>
							<Brand tw="h-12 w-auto" />
						</div>
						<div tw="-mr-2">
							<button
								type="button"
								tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
								onClick={closeMobile}
							>
								<span tw="sr-only">Close menu</span>
								{/** Heroicon name: x */}
								<svg
									tw="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
					</div>
					<div tw="pt-5 pb-6" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
						<div tw="px-2 space-y-1" role="none">
							<Menu>
								<div tw="border-b">
									<Menu.Button as={FooterMenuButton}>Pool Cleaners</Menu.Button>
									<Menu.Items tw="px-3 flex flex-col">
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black flex items-center" to="/dolphin">
												<div
													tw="w-8 h-8 bg-contain bg-no-repeat mr-2"
													style={{
														backgroundImage:
															'url(https://images.ctfassets.net/ftcnv5up9dxv/68iEdnis34dT15KA1D2rMT/bf3086aede408b1961b4aa9d385075e7/D_is_for_Dolphin.png)',
													}}
												></div>
												<p>
													<span tw="font-bold">Dolphin</span>
													<br></br>
													Robotic Pool Cleaners
												</p>
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/m-line">
												Dolphin M Line
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/s-line">
												Dolphin S Line
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/x-line">
												Dolphin X Line
											</Link>
										</Menu.Item>
										{/* <Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/e-line">
												Dolphin E Line
											</Link>
										</Menu.Item> */}
										{process.env.GATSBY_LOCALE !== 'en-PH' ? (
											<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
												<Link tw="text-sm text-gray-600 hover:text-black" to="/dolphin/liberty-line">
													Dolphin Liberty Line
												</Link>
											</Menu.Item>
										) : (
											''
										)}
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/which-model">
												Which Robot?
											</Link>
										</Menu.Item>
									</Menu.Items>
								</div>
							</Menu>
							<Menu>
								<div tw="border-b">
									<Menu.Button as={FooterMenuButton}>Commercial</Menu.Button>
									<Menu.Items tw="px-3 flex flex-col">
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link tw="text-sm text-gray-600 hover:text-black flex items-center" to="/dolphin/commercial">
												<div
													tw="w-8 h-8 bg-contain bg-no-repeat mr-2"
													style={{
														backgroundImage:
															'url(https://images.ctfassets.net/ftcnv5up9dxv/68iEdnis34dT15KA1D2rMT/bf3086aede408b1961b4aa9d385075e7/D_is_for_Dolphin.png)',
													}}
												></div>
												<p>
													<span tw="font-bold">Dolphin Commercial</span>
													<br></br>
													Robotic Pool Cleaners
												</p>
											</Link>
										</Menu.Item>
									</Menu.Items>
								</div>
							</Menu>
							<Menu>
								<div tw="border-b">
									<Menu.Button as={FooterMenuButton}>Support</Menu.Button>
									<Menu.Items tw="px-3 flex flex-col">
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2 border-b">
											<Link tw="text-sm text-gray-600 hover:text-black" to="/contact-us">
												Contact
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2 border-b">
											<Link
												target="_blank"
												rel="noreferrer"
												tw="text-sm text-gray-600 hover:text-black"
												to="https://maytronics.com.au/support/faq/"
											>
												Knowledge Base
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2 border-b">
											<Link
												target="_blank"
												rel="noreferrer"
												tw="text-sm text-gray-600 hover:text-black"
												to="https://maytronics.com.au/support/registration"
											>
												Warranty Registration
											</Link>
										</Menu.Item>
										<Menu.Item tw="py-3 hover:bg-gray-100 px-2">
											<Link
												target="_blank"
												rel="noreferrer"
												tw="text-sm text-gray-600 hover:text-black"
												to="https://maytronics.com.au/support/dolphins-purchased-from-outside-australia/"
											>
												Overseas Purchases
											</Link>
										</Menu.Item>
									</Menu.Items>
								</div>
							</Menu>
						</div>
						{/* <div role="none" tw="mt-6 px-5">
							<Link
								to="#"
								tw="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-yellow-500 to-yellow-600 text-gray-800 font-medium hover:from-yellow-400 hover:to-yellow-500"
							>
								SHOP NOW
							</Link>
						</div> */}
						<div role="none" tw="mt-6 px-5">
							<a
								href="/contact-us?subject=demo"
								tw="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-yellow-500 to-yellow-600 text-gray-800 font-medium hover:from-yellow-400 hover:to-yellow-500"
							>
								BOOK A DEMO
							</a>
						</div>
					</div>
				</div>
			</Transition>
		</header>
	)
}

export default Header
